@import url("https://fonts.googleapis.com/css2?family=Almendra&family=Inter&family=Montserrat:wght@500&family=Poppins:ital,wght@1,300;1,600;1,900&family=Skranji&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Almendra&family=Inter&family=Montserrat:wght@500&family=Poppins:ital,wght@1,300;1,600;1,900&family=Skranji&display=swap");
:root {
  --logo_height: min(40vh, 275px);
  --button_width: min(65%, 200px);
}
@font-face {
  font-family: Dalek;
  src: url(Dalek.ttf);
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}

.body {
  display: block;
  align-items: center;
  background-image: url("/src/assets/BgImg5.png");
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  height: 96vh;
  background-image: url("/src/assets/greek_temple.png");
}

/* .logoholder {
  background-image: url("/src/assets/Waves_Circle.png");
  height: var(--logo_height);
  width: var(--logo_height);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: calc(50% - var(--logo_height) / 2);
  opacity: 90%;
  top: 4%;
}
.logo_img {
  margin-top: 30%;
  width: var(--logo_height);
  position: absolute;
  left: calc(50% - var(--logo_height) / 2);
} */
.logoholder {
  background-image: url("/src/assets/Waves_Circle.png");
  height: var(--logo_height);
  width: var(--logo_height);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: calc(50% - var(--logo_height) / 2);
  opacity: 90%;
  top: calc(50% - var(--logo_width) / 2);
}
.logo_img {
  width: var(--logo_height);
  position: relative;
  padding-top: 10%;
  padding-bottom: 10%;
}
.reg_suc {
  width: calc(var(--logo_height) * 0.6);
  position: relative;
  padding-top: 10%;
  padding-bottom: 10%;
}
.buttonholder {
  position: relative;
  top: 30vh;
}

.button {
  width: var(--button_width);
  height: 4%;
  border-radius: 5vh;
  position: absolute;
  left: calc(50% - var(--button_width) / 2);
  font-weight: bolder;
  cursor: pointer;
  transition: all 0.5s;
  font-size: 1.20rem;
  font-family: "Dalek", cursive;
  color: rgba(185, 185, 185, 1);
  background-image: url("assets/final_bt_background.png");
  background-size: cover;
  border: 0.599px solid #000;
  filter: brightness(92%);
  background-color: rgba(25, 52, 37, 0.5);
  background-blend-mode: multiply;
}
.button:hover {
  color: yellow;
  height: 6%;
  width: calc(var(--button_width) + 2%);
  left: calc(49% - var(--button_width) / 2);
  font-size: 1.5rem;
  opacity: 0.85;
  font-family: "Dalek", cursive;
  border: 1.06918px solid #000000;
  box-shadow: 0px 0px 20px #ffef98;
  background-image: url("assets/final_bt_background.png");
  background-size: cover;
}
.button:active {
  background-color: lightgrey;
  transform: translateY(1px) translateX(0.5px);
  color: yellow;
  height: 6%;
  width: calc(var(--button_width) + 2%);
  left: calc(49% - var(--button_width) / 2);
  font-size: larger;
  opacity: 0.85;
  font-family: "Skranji", cursive;
  border: 1.06918px solid #000000;
  box-shadow: 0px 0px 20px #ffef98;
  background-image: url("assets/background_bt_1.png");
  background-size: cover;
}

.button1 {
  top: 45%;
}

.button2 {
  top: 52%;
}

.button3 {
  top: 59%;
}

.button4 {
  top: 66%;
}
.button5 {
  top:73%;
}
.button6 {
  top:80%;
}
App {
  box-sizing: border-box;
  font-family: "Skranji", cursive;
  padding: 2rem;
  background: black;
  height: 100%;
}

.reg-heading {
  color: white;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
  font-family: Skranji;
  font-size: 48px;
  font-weight: 400;
  line-height: 65px;
  letter-spacing: 0em;
  text-align: left;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  color: white;
  background-color: black;
  height: 4vh;
}

.all_logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rotate {
  animation: rotation 40s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 600px) {
  .logo_img {
    padding-top: 20%;
    padding-bottom: 20%;
  }
}
@media (max-aspect-ratio: 3/2) and (orientation: portrait) {
  .logo_img {
    padding-top: 21%;
    padding-bottom: 21%;
  }
}