.RegWindow {
  color: #ffffff8f;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  /* background-color: #484848; */
  font-family: "Inter", sans-serif;
  padding: 5%;
  border-right: white 1px solid;
  width: 50%;
  justify-content: left;
  align-items: center;
  margin-top: 2rem;
  left: 5%;
}

form {
  display: flex;
  flex-direction: column;
  
  }

  


.RegWindow input {
  font-family: "Inter", sans-serif;
  background: none;
  border: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #ffffff8f;
  padding: 8px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3rem;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff8f;
  color: white;
}

.RegWindow input:focus {
  outline: none;
}

.RegWindow select {
  font-family: "Inter", sans-serif;
  background: none;
  border: 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid #ffffff8f;
  padding: 8px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3rem;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff8f;
}

.RegWindow option {
  color: #484848;
  background: whitesmoke;
}

@media (max-width:600px){


  .RegWindow{
    border-right: 0px;
    
  }
}

@media (max-height:810px){
  .RegWindow{
    margin-top: 5rem;
  }
}

@media (max-height:730px){
  .RegWindow{
    margin-top: 9rem;
  }
}