.Placement {
    /* display: flex; */
    /* Set the container as a flex container */
    /* justify-content: space-between; */
    /* Distribute items with space between them */
    align-items: center;
    /* Center items vertically */
    height: 550px;
    /* border: 1px solid #ccc; */
    padding-top: 10px;
    padding-bottom: 50px;
    flex-basis: 50%;
    /* background-color:"#484848"; */
}



.finaltouch{
    display: flex;
    
}


.input {
    font-family: 'Inter', sans-serif;
    background: none;
    border: 0;
    margin-bottom: 2rem;
    border-bottom: 1px solid #FFFFFF8F;
    padding: 18px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.3rem;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF8F;
    color: white;
    margin-left: 7%;

}

.input:focus {
    outline: none;
}

.indvbtn {
    font-family: Skranji;
}

.teambtn {
    font-family: Skranji;
}

.adder {
    width: 36px;
    height: 36px;
    border-radius: 9999px;
    background-color: black;
    text-align: right;
    flex-direction: row-reverse;
    cursor: pointer;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:36px
}

.tabs{
    text-align: center;
}

.move {
    width: -moz-fit-content;
    display: flex;
    justify-content: center;
    border-radius: 9999px;
    background-color: #6a6a6a;
    display: flex;
    width: fit-content;
    padding: 5px 10px;
    margin: auto
}

.team-names{
    width: 30vw;
}

.team-names::-webkit-scrollbar {
    width: 5px;
  }

.team-names::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

.team-names::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
  }

  .plsend{
    height: 500px;
    width: 100vh;
  }

  .respond{
    width: 32vw;
  }

  @media (max-width:600px){
    .over{
        margin: 0 -2.8rem 0;
    }
    .respond{
        margin: 1rem 4.5rem;
        width:350%;
    }
    .plsend{
        height: 200px;
        width: 10vh;
    }

    .finaltouch{
        margin-left:1rem;
        padding-top: 1rem;
        padding-bottom: 5%;
    }

    .team-names{
        width: 325%;
    }
  }
