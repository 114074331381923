.container {
  box-sizing: border-box;
  font-family: "Skranji", cursive;
  padding: 8px;
  background: transparent;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 90%;
  
}

.reg-heading {
  color: white;
  padding: 0;
  margin: 0;
  position: absolute;
  margin-bottom: 16px;
  font-family: Skranji;
  font-size: 24px;
  font-weight: 400;
  line-height: 100px;
  letter-spacing: 0em;
  text-align: center;
  left: 13%;
}

.button-holder {
  height: fit-content;
  z-index: -1;
  width: 100% ;
  background: #393939;
  
}

.regmodal{
  width: 100%;
  background: #393939;
}

.register-btn {
  font-family: Skranji;
  background-color: black;
  padding: 0.5rem 2.5rem;
  border-radius: 2rem;
  text-align: center;
  margin: 1rem auto;
  width: fit-content;
  font-size: 15px;
  cursor: pointer;
  color: white;
  margin-bottom: 20px;
  box-shadow: 0 0 5px 0px wheat;
}



.register-btn:hover {
  color: yellow;
  background-color: black;
  box-shadow: 0 0 5px 0px wheat, 0 0 10px 5px wheat, 0px 0px 10px 0px wheat;
}

@media (max-width:600px){
  .regmodal {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    width: 100%;
  }

  .reg-heading{
    top: -0.5rem;
  }

}

@media (max-width:600px) and (max-height:739px){

  .button-holder{
    padding-top: 3rem;
    width: 100%;
    
  }
}

@media (max-width:600px) and (740px<= height <=950px){
  .button-holder{
    padding-top: 3rem;
    width: 100%;
  }
}